import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbLayout from "../Layout/BreadcrumbLayout";
import { isMobile } from "react-device-detect";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { change_Lang } from "../../action/Helper_action";
import setToken from "../../util/SetToken";
import Instance from "../../util/Instance";
import Time from "react-time-format";
import AddProduct from "./AddProduct";
import UpdateProduct from "./UpdateProduct";
const ListProducts = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const [items_array, setitems_array] = useState([]);
  const [view_obj, setview_obj] = useState({});
  const [isModalVisible, setisModalVisible] = useState(false);
  const [U_isModalVisible, setU_isModalVisible] = useState(false);
  const handleCancel = () => {
    setisModalVisible(false);
  };
  const showModal = () => {
    setisModalVisible(true);
  };
  const U_handleCancel = () => {
    setU_isModalVisible(false);
  };
  const U_showModal = (id) => {
    View_Item(id);
    setU_isModalVisible(true);
  };
  const GetItems = async () => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      const res = await Instance.get(`/api/v1/Items`, config);
      setitems_array(res.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetItems();
  }, []);
  const View_Item = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      const res = await Instance.get(`/api/v1/Item/${id}`, config);
      setview_obj(res.data.data);
    } catch (error) {}
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const onDeleteProduct_Api = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      await Instance.delete(`/api/v1/Item/${id}`, config);
      setTimeout(() => {
        GetItems();
      }, 3000);
    } catch (error) {}
  };
  const onDeleteProduct = (id) => {
    return Swal.fire({
      title: dispatch(change_Lang("Are you sure")),
      html: `<label class="fk font-weight-bold" >${dispatch(
        change_Lang("You won't be able to delete this!")
      )} </label>`,
      showCancelButton: true,
      confirmButtonText: dispatch(change_Lang("Yes, delete it")),
      cancelButtonText: dispatch(change_Lang("cancel")),
      buttonsStyling: { fontFamily: "fk" },
      iconHtml: "؟",
      confirmButtonColor: "darkred",
      cancelButtonColor: "#102770",
      iconColor: "#363636",
      customClass: {
        confirmButton: `${lang === "en" ? "btn" : "btn fk"}`,
        cancelButton: `${lang === "en" ? "btn" : "btn fk"}`,
        title: `${lang === "en" ? "btn" : "btn fk font-weight-bold"}`,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteProduct_Api(id);
        Swal.fire({
          title: dispatch(change_Lang("Delete")),
          html: `<label class="fk" >${dispatch(
            change_Lang("You successfully tainted your work")
          )}</label>`,
          timer: 1500,
          icon: "success",
          confirmButtonColor: "#007bff",
          iconColor: "green",
        });
      }
    });
  };
  const columns = [
    {
      title: dispatch(change_Lang("Product Brand")),
      dataIndex: "Manufacture",
      key: "Manufacture",
      width: "12.5%",
      className: `${lang === "en" ? "" : "fk"}`,
      ...getColumnSearchProps("Manufacture"),
    },
    {
      title: dispatch(change_Lang("Product Name")),
      dataIndex: "Name",
      key: "Name",
      width: "12.5%",
      className: `${lang === "en" ? "" : "fk"}`,
      ...getColumnSearchProps("Name"),
    },
    {
      title: dispatch(change_Lang("price")),
      dataIndex: "Price",
      key: "Price",
      width: "9.375%",
      className: `${lang === "en" ? "" : "fk"}`,
      render: (Price) => (
        <NumericFormat
          displayType="text"
          thousandSeparator={true}
          value={Price}
          className={`${lang === "en" ? "" : "fk"}`}
        />
      ),
    },
    {
      title: dispatch(change_Lang("Currency")),
      dataIndex: "Currency",
      key: "Currency",
      width: "9.375%",
      className: `${lang === "en" ? "" : "fk"}`,
    },
    {
      title: dispatch(change_Lang("date")),
      dataIndex: "created_at",
      key: "created_at",
      width: "14.285%",
      className: `${lang === "en" ? "" : "fk"}`,
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ["descend", "ascend"],
      render: (createdAt) => (
        <Time
          value={createdAt}
          format="YYYY-MM-DD"
          style={{ color: "black" }}
          className={`${lang === "en" ? "" : "fk"}`}
        />
      ),
    },
    {
      title: dispatch(change_Lang("Image")),
      dataIndex: "ImagePath",
      key: "ImagePath",
      width: "12.5%",
      className: `${lang === "en" ? "" : "fk"}`,
      render: (ImagePath) => (
        <PhotoProvider>
          <div className="foo">
            <PhotoView
              src={ImagePath}
              alt="item_img"
              style={{ objectFit: "cover" }}
            >
              <img
                style={{ width: "35px", height: "35px" }}
                src={ImagePath}
                alt="item_img"
              />
            </PhotoView>
          </div>
        </PhotoProvider>
      ),
    },
    {
      title: dispatch(change_Lang("Action")),
      dataIndex: "id",
      key: "id",
      className: `${lang === "en" ? "" : "fk"}`,
      render: (id) => (
        <>
          <Tooltip title="Update">
            <Button
              style={{ background: "#efefed", color: "#4064d7" }}
              shape="circle"
              onClick={() => U_showModal(id)}
              icon={<EditOutlined style={{ color: "#007bff" }} />}
            />
          </Tooltip>
          &nbsp;
          <Tooltip title="delete">
            <Button
              style={{ background: "#efefed", color: "#4064d7" }}
              shape="circle"
              onClick={() => onDeleteProduct(id)}
              icon={<DeleteOutlined style={{ color: "#007bff" }} />}
            />
          </Tooltip>
        </>
      ),
      width: "12.5%",
    },
  ];
  return (
    <>
      <BreadcrumbLayout parent={"Dashboard"} title={"List Products"} />
      <div className="row ">
        <div
          className={
            isMobile
              ? "col-md-4 d-flex justify-content-center"
              : "col-md-4 d-flex justify-content-start"
          }
        >
          <Button
            type="primary"
            shape="default"
            style={{
              background: "#007bff",
              color: "#fff",
              fontSize: 14,
            }}
            onClick={showModal}
            htmlType="submit"
            className={`${lang === "en" ? "" : "fk"}`}
          >
            <i className="fas fa fa-plus-circle"></i>&nbsp;
            {dispatch(change_Lang("Add Product"))}
          </Button>
        </div>
        <div className={isMobile ? "col-md-4" : "col-md-4 "}>
          <div className="d-flex justify-content-center ">
            <h4
              style={{ fontWeight: "bold", color: "#007bff", fontSize: 20 }}
              className={`${lang === "en" ? "" : "fk"}`}
            >
              {dispatch(change_Lang("List Products"))}
            </h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group form-inline" style={{ width: "90%" }}>
            <Input
              value={"Jozardan Company"}
              className="text-center"
              size="middle"
              readOnly
              addonBefore={
                <i
                  style={{
                    color: "#007bff",
                  }}
                  className="fa-solid fa-qrcode text-center"
                  aria-hidden="true"
                ></i>
              }
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={items_array}
        scroll={{
          x: "100%",
        }}
      />
      {/* Add Products */}
      <AddProduct
        GetItems={GetItems}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <UpdateProduct
        GetItems={GetItems}
        isModalVisible={U_isModalVisible}
        handleCancel={U_handleCancel}
        view_obj={view_obj}
      />
    </>
  );
};
export default withRouter(ListProducts);
