import React from "react";
import { QRCode } from "antd";
import { change_Lang } from "../../action/Helper_action";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/j_logo.png";
const PrintQR = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  return (
    <>
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={600 / 4}
            value={`https://jozardan-company.com/`}
            icon={logo}
            bgColor="white"
            color="#AA7500"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            {dispatch(change_Lang("Check this out to see our products."))}
          </h1>
        </div>
      </div>
    </>
  );
};
export default PrintQR;
