import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;
const FooterLayout = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      Jozardan-Company ©2024 Created by CodeRiver Company
    </Footer>
  );
};

export default FooterLayout;
