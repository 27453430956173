import React from "react";
import { Button, QRCode } from "antd";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/j_logo.png";
import { change_Lang } from "../../action/Helper_action";
import { Link } from "react-router-dom";
const Qrcode = () => {
  const dispatch = useDispatch();
  const Qrcode_Print = () => {
    window.setTimeout("window.print()", 2000);
  };
  return (
    <>
      <div className="d-flex justify-content-center mt-3">
        <QRCode
          errorLevel="H"
          size={500}
          iconSize={600 / 4}
          icon={logo}
          value={`https://jozardan-company.com/`}
          bgColor="white"
          color="#AA7500"
        />
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Link to="/qrcode-print">
          <Button
            className="ml-4 fk px-3"
            type="primary"
            shape="default"
            style={{
              background: "#000",
              color: "#fff",
            }}
            htmlType="submit"
            onClick={Qrcode_Print}
          >
            {dispatch(change_Lang("Print"))}
          </Button>
        </Link>
      </div>
    </>
  );
};
export default Qrcode;
