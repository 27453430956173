import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Input, Form, Divider, Space, Button, Drawer } from "antd";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { change_Lang } from "../../action/Helper_action";
import setToken from "../../util/SetToken";
import Instance from "../../util/Instance";
import ShowImageModel from "../products/ShowImageModel";
import Compressor from "compressorjs";
const UpdateCategory = ({
  GetCategory,
  isModalVisible,
  handleCancel,
  view_obj,
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const [img_model, setimg_model] = useState(false);
  const [ImagePath, setImagePath] = useState("");
  const [nameKu, setnameKu] = useState("");
  const [nameAr, setnameAr] = useState("");
  const [nameEn, setnameEn] = useState("");
  useEffect(() => {
    setnameKu(view_obj && view_obj.nameKu);
    setnameAr(view_obj && view_obj.nameAr);
    setnameEn(view_obj && view_obj.nameEn);
    setImagePath(view_obj && view_obj.ImagePath);
  }, [view_obj]);

  const NewPage = () => {
    setnameKu("");
    setnameAr("");
    setImagePath("");
    setnameEn("");
  };
  const onUpdateCategory = async () => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      const data = {
        ImagePath,
        nameAr,
        nameEn,
        nameKu,
      };
      if (nameKu === "") {
        Swal.fire({
          title: "OPS",
          icon: "error",
          html: `<b class="fk">
            ${dispatch(change_Lang("Please Check Inputs"))}
          </b>`,
          confirmButtonColor: "#EA994A",
          confirmButtonText: "Ok",
          timer: 1300,
          iconColor: "darkred",
        });
      } else {
        await Instance.put(`/api/v1/Catigory/${view_obj.id}`, data, config);
        handleCancel();
        NewPage();
        Swal.fire({
          title: "Good job!",
          html: `<b class="fk">${dispatch(
            change_Lang("You successfully tainted your work")
          )}</b>`,
          icon: "success",
          confirmButtonText: dispatch(change_Lang("success")),
          buttonsStyling: { fontFamily: "fk" },
          confirmButtonColor: "#EA994A",
          timer: 3000,
          iconColor: "#363636",
        });
        setTimeout(() => {
          GetCategory();
        }, 3000);
      }
    } catch (error) {}
  };
  const _handleImgChange = (files) => {
    let reader = new FileReader();
    let file = files[0];

    // Compress the image using compressorjs
    new Compressor(file, {
      quality: 0.6, // Adjust quality as needed (0.6 means 60% quality)
      success(result) {
        // 'result' is the compressed Blob
        const compressedFile = new File([result], "compressed.jpg", {
          type: "image/jpeg",
        });

        // Use the compressed file or its data URL as needed
        // Example: Set the compressed image as the image path
        reader.onloadend = () => {
          let img = reader.result;
          setImagePath(img);
        };

        // Read the compressed file as a data URL
        reader.readAsDataURL(compressedFile);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };
  const Open_ImageModel = (i) => {
    setimg_model(!img_model);
  };
  const OnDelete_Image = (i) => {
    setImagePath("");
  };
  const Close_ImageModel = () => {
    setimg_model(false);
  };
  const containerStyle = {
    textAlign: "center",
  };
  return (
    <Drawer
      title={dispatch(change_Lang("Update Category"))}
      width={720}
      onClose={handleCancel}
      className={`${lang === "en" ? "text-center" : " fk"}`}
      open={isModalVisible}
      style={containerStyle}
      extra={
        <Space>
          <Button
            onClick={handleCancel}
            className={`${lang === "en" ? "" : "fk"}`}
          >
            {dispatch(change_Lang("cancel"))}
          </Button>
          <Button
            onClick={onUpdateCategory}
            type="primary"
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ background: "#EA994A" }}
          >
            {dispatch(change_Lang("Update"))}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical">
        <div className="row">
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Name (Kurdish)"))}
            </label>
            <Input
              size="middle"
              value={nameKu}
              name="nameKu"
              type="text"
              onChange={(e) => setnameKu(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Name (Arabic)"))}
            </label>
            <Input
              size="middle"
              value={nameAr}
              name="nameAr"
              type="text"
              onChange={(e) => setnameAr(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Name (English)"))}
            </label>
            <Input
              size="middle"
              value={nameEn}
              name="nameEn"
              type="text"
              onChange={(e) => setnameEn(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <br />
        <Divider
          className={`${
            lang === "en"
              ? "d-flex justify-content-center"
              : " d-flex justify-content-center fk"
          }`}
          style={{
            fontSize: "16px",
            color: "#052f43",
            fontWeight: `${lang === "en" ? "" : "bold"}`,
          }}
        >
          {dispatch(change_Lang("Product Image"))}
        </Divider>
        <div className="row">
          <div className="col-10 col-md-12">
            {ImagePath === "" ? (
              <Dropzone
                onDrop={(files) => _handleImgChange(files)}
                multiple={true}
                maxSize={80000000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      style={{
                        height: "120px",
                        border: "1px dashed lightgray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <i
                        className="fas fa-upload mr-5"
                        style={{
                          fontSize: "24px",
                          color: "#052f43",
                        }}
                      ></i>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    onClick={Open_ImageModel}
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: 20,
                    }}
                    src={`${ImagePath}`}
                    alt={`${ImagePath}`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
      {img_model === false ? null : (
        <ShowImageModel
          Close_Models={Close_ImageModel}
          OnDelete_Image={OnDelete_Image}
          ImagePath={ImagePath}
        />
      )}
    </Drawer>
  );
};

export default UpdateCategory;
