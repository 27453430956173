import React, { useState } from "react";
import {
  ShoppingCartOutlined,
  LogoutOutlined,
  UserOutlined,
  QrcodeOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { change_Lang } from "../../action/Helper_action";
import { Layout, Menu } from "antd";
const { Sider } = Layout;
const SidebarLayout = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const [collapsed, setCollapsed] = useState(true);
  const LogOut_User = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    window.location.reload();
  };
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ background: "#000" }}
    >
      <div
        style={{
          height: 32,
          margin: 16,
          background: "#000",
        }}
      />
      <Menu
        theme="dark"
        mode="inline"
        className="text-center"
        style={{ background: "#000" }}
      >
        <Menu.Item key="/list-products">
          <Link to={"/list-products"}>
            <ShoppingCartOutlined
              style={{ color: "#EA994A", fontWeight: "bolder" }}
            />
            <span className={`${lang === "en" ? "" : "fk"}`}>
              {dispatch(change_Lang("List Products"))}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/list-category">
          <Link to={"/list-category"}>
            <AppstoreAddOutlined
              style={{ color: "#EA994A", fontWeight: "bolder" }}
            />
            <span className={`${lang === "en" ? "" : "fk"}`}>
              {dispatch(change_Lang("List Category"))}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/qrcode-image">
          <Link to={"/qrcode-image"}>
            <QrcodeOutlined
              style={{ color: "#EA994A", fontWeight: "bolder" }}
            />
            <span className={`${lang === "en" ? "" : "fk"}`}>QR Code</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/profile-account">
          <Link to={"/profile-account"}>
            <UserOutlined style={{ color: "#EA994A", fontWeight: "bolder" }} />
            <span className={`${lang === "en" ? "" : "fk"}`}>
              {dispatch(change_Lang("Profile Account"))}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/login" onClick={LogOut_User}>
          <Link to={"/login"}>
            <LogoutOutlined
              style={{ color: "#EA994A", fontWeight: "bolder" }}
            />
            <span className={`${lang === "en" ? "" : "fk"}`}>
              {dispatch(change_Lang("Log out"))}
            </span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SidebarLayout;
